<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Erişim Hatası</h5>
				<p>Bu sayfaya erişim için yetkiniz bulunmamaktadır!</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
